/* eslint-disable camelcase */
export const MutationTypes = {
  SET_SETTING: 'SET_SETTING'
}

const state = () => ({
  mainNav: [],
  default_seo: {}
})

const mutations = {
  [MutationTypes.SET_SETTING] (state, { key, value }) {
    state[key] = value
  }
}

const actions = {
  async setAppSettings (
    { commit },
    { main_navigation, default_seo }
  ) {
    try {
      await commit(MutationTypes.SET_SETTING, {
        key: 'mainNav',
        value: main_navigation || []
      })
      await commit(MutationTypes.SET_SETTING, {
        key: 'default_seo',
        value: default_seo || {}
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
