export const state = () => ({
  sponsors: []
})

export const getters = {
  getterSponsors: (state) => {
    return state.sponsors
  }
}

export const mutations = {
  updateSponsors: (state, payload) => {
    state.sponsors = payload
  }
}

export const actions = {
  updateActionSponsors ({ commit }, payload) {
    commit('updateSponsors', payload)
  }
}
