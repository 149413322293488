//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  fetch () {
    this.checkRouteQueryForView()
  },
  computed: {
    ...mapGetters({
      viewlist: 'view/getterViewlist',
      view: 'view/getterView'
    }),
    onAbout () {
      return /^(\/ueber-umseck\/?)$/.test(this.$route.path)
    },
    onHome () {
      return /^(\/)$/.test(this.$route.path)
    },
    fullPath () {
      return this.$route.fullPath
    }
  },
  watch: {
    fullPath () {
      this.checkRouteQueryForView()
    }
  },
  methods: {
    checkRouteQueryForView () {
      const view = this.$route.query.view
      if (!view) { return }
      const viewIndex = this.viewlist.findIndex(item => item.id === view)
      if (viewIndex >= 0) {
        this.handleChange(viewIndex)
      } else {
        this.handleChange(0)
      }
    },
    handleChange (index) {
      this.$store.dispatch('view/updateView', index)
    }
  }
}
