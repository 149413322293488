//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data () {
    return {
      startScreen: true,
      functional: true,
      mapboxgl: false
    }
  },
  computed: {
    ...mapState({
      showCookieBanner: state => state.gdpr.showCookieBanner
    }),
    isExceptionRoute () {
      return this.$route.path.startsWith('/datenschutz')
    }
  },
  mounted () {
    this.$nextTick(() => {
      const services = this.$store.state.gdpr.service
      Object.keys(services).forEach((key) => {
        this[key] = services[key]
      })
    })
  },
  methods: {
    saveEssential () {
      const services = this.$store.state.gdpr.service
      Object.keys(services).forEach((key) => {
        this[key] = false
      })
      this.functional = true
      this.saveSettings()
    },
    enableAll () {
      const services = this.$store.state.gdpr.service
      Object.keys(services).forEach((key) => {
        this[key] = true
      })
      this.saveSettings()
    },
    saveSettings () {
      const services = this.$store.state.gdpr.service
      Object.keys(services).forEach((key) => {
        this.$store.dispatch('gdpr/setService', { key, value: this[key] })
      })
      this.$store.dispatch('gdpr/closeCookieBanner')
    },
    toggleScreen () {
      this.startScreen = !this.startScreen
    }
  }
}
