export const state = () => ({
  categories: []
})

export const getters = {
  getterCategories: (state) => {
    return state.categories
  }
}

export const mutations = {
  updateCategories: (state, payload) => {
    state.categories = payload
  }
}

export const actions = {
  updateActionCategories ({ commit }, payload) {
    commit('updateCategories', payload)
  }
}
