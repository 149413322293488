import isArray from 'lodash/isArray'

export default (context, inject) => {
  const tag = (button) => {
    if (isArray(button)) {
      button = button[0]
    }

    if (button.link) {
      button = button.link
    }

    switch (button?.linktype) {
      case 'url':
        return 'a'
      case 'story':
        return 'nuxt-link'
      case 'email':
        return 'a'
      default:
        return 'div'
    }
  }

  const attributes = (button) => {
    if (isArray(button)) {
      button = button[0]
    }

    if (button.link) {
      button = button.link
    }

    let anchor = ''
    if (button.anchor) {
      anchor = '#' + button.anchor
    }

    switch (button?.linktype) {
      case 'email':
        return { href: `mailto:${button.url}`, target: '_blank' }
      case 'url':
        return { href: button.url + anchor || '#', target: '_blank' }
      case 'story':
        return {
          to: {
            path: button.story?.fullSlug
              ? `/${button.story.fullSlug}`
              : button.story?.full_slug
                ? `/${button.story.full_slug}`
                : '',
            hash: anchor
          }
        }

      default:
        return {}
    }
  }

  const utils = {
    attributes,
    tag
  }

  inject('utils', utils)
  context.$utils = utils
}
