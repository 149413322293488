import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import RichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import { Block } from '@marvr/storyblok-rich-text-types'
import CustomHeading from '~/components/utils/CustomHeading.vue'

Vue.use(VueCompositionApi)
Vue.use(RichTextRenderer, {
  resolvers: {
    blocks: {
      [Block.HEADING]: CustomHeading
    }
  }
})
