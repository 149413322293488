//
//
//
//
//
//

const umlautMap = {
  ü: 'ue',
  ä: 'ae',
  ö: 'oe',
  ß: 'ss'
}

export default {
  name: 'SbHeading',
  props: {
    attrs: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      id: this.getId(this.$slots.default)
    }
  },
  computed: {
    tag () {
      return `h${this.attrs.level}`
    }
  },
  methods: {
    getId (vNodes) {
      if (!vNodes || !vNodes.length) { return '' }
      return vNodes.map((node) => {
        if (node.tag) {
          return this.getId(node.componentOptions.children)
        }
        return node.text
      }).filter(Boolean).join('').toLowerCase().replace(new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'), a => umlautMap[a]).replace(/[^a-zA-Z0-9-_]/g, '-').replace(/-$/, '')
    }
  }
}
