//
//
//
//
//
//

import { v4 as uuidv4 } from 'uuid'

export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    active: {
      type: Number,
      default: -1
    },
    name: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      localeName: this.getName(),
      localeActive: this.active
    }
  },
  watch: {
    active (newVal) {
      if (newVal === this.localeActive) { return }
      this.localeActive = newVal
    },
    localeActive (newVal) {
      this.$emit('change', newVal)
    }
  },
  methods: {
    getName () {
      return this.name || uuidv4()
    }
  }
}
