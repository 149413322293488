import Vue from 'vue'
import VueTailwind from 'vue-tailwind'

import {
  TModal,
  TToggle,
  TButton
} from 'vue-tailwind/dist/components'

const components = {
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed',
        wrapper: 'relative mx-auto',
        modal: 'overflow-visible relative ',
        close: 'flex items-center justify-center'
      },
      classes: {
        overlay: 'z-40 bg-black bg-opacity-50 backdrop-blur-md',
        wrapper: 'z-50 max-w-lg px-3 py-12',
        modal: 'bg-white shadow rounded',
        body: 'p-3',
        header: 'border-b border-gray-100 p-3 rounded-t',
        footer: 'bg-gray-100 p-3 rounded-b',
        close: 'bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: ''
      }

    }
  },
  't-toggle': {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
        wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
        wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
        button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
        buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
        checkedPlaceholder: 'inline-block',
        uncheckedPlaceholder: 'inline-block'

      },
      classes: {
        wrapper: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-0 focus:outline-none',
        wrapperChecked: 'bg-[#EDE494] rounded-full border-2 border-transparent focus:ring-0 focus:outline-none',
        wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-0 focus:outline-none',
        wrapperCheckedDisabled: 'bg-[#EDE494] rounded-full border-2 border-transparent focus:ring-0 focus:outline-none',
        button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
        buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs',
        checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
        uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
      }

    }
  },
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'block px-4 py-2 transition duration-100 ease-in-out focus:border-0 focus:ring-0 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-black bg-[#EDE494]/90 border border-black shadow-sm rounded hover:bg-[#EDE494]',
      variants: {
        secondary: 'text-gray-800 bg-white border border-black shadow-sm rounded-full hover:text-gray-600 text-xs'
        // error: 'text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600',
        // success: 'text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600',
        // link: 'text-blue-500 underline hover:text-blue-600'
      }
    }
  }
}

Vue.use(VueTailwind, components)
