//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    justify: {
      type: String,
      default: 'center'
    },
    round: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    justifyClass () {
      switch (this.justify) {
        case 'start':
          return 'justify-start'
        case 'end':
          return 'justify-end'
        case 'between':
          return 'justify-between'
        case 'around':
          return 'justify-around'
        case 'evenly':
          return 'justify-evenly'
        default:
          return 'justify-center'
      }
    },
    paddingClass () {
      if (this.round) {
        return 'p-2'
      }
      return 'py-2 px-4'
    }
  }
}
