//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    errorCode () {
      return this.error?.statusCode
    }
  }
}
