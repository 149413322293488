import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0fa8695e&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/vercel/path0/components/layout-components/Nav.vue').default,Footer: require('/vercel/path0/components/layout-components/Footer.vue').default,CookieBanner: require('/vercel/path0/components/utils/CookieBanner/CookieBanner.vue').default})
