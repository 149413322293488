import Vue from 'vue'
import Nujek from '~nujek-ui/plugin'
import { NjSection } from '~nujek-ui/components'

const settings = {
  NjSection: {
    component: NjSection,
    props: {
      fixedClasses: {
        // https://github.com/tailwindlabs/tailwindcss-typography#overriding-max-width
        wrapper: 'w-full flex justify-center',
        container: 'w-full relative'
      },
      variants: {
        'section-2xl': {
          wrapper: 'my-8 md:my-10 xl:my-12 px-6 md:px-12',
          container: 'max-w-screen-2xl'
        },
        'section-lg': {
          wrapper: 'my-8 md:my-10 xl:my-12 px-6 md:px-12',
          container: 'max-w-screen-lg'
        },
        hero: {
          wrapper: 'px-6 md:px-12',
          container: 'max-w-screen-2xl'
        },
        footer: {
          wrapper: 'mb-4 md:mb-5 xl:mb-6 mt-8 md:mt-10 xl:mt-12 px-6 md:px-12',
          container: 'max-w-screen-2xl'
        },
        'social-share': {
          wrapper: 'px-0 sm:px-6 md:px-12',
          container: 'max-w-screen-2xl'
        }
      }
    }
  }
}

Vue.use(Nujek, settings)
