const actions = {
  async nuxtServerInit ({ dispatch }) {
    await dispatch('fetchStoryblokSettings')
  },
  async nuxtClientInit ({ dispatch }, ctx) {
    await dispatch('parseAppCookies', ctx)
    if (ctx.query._storyblok) { await dispatch('gdpr/setCookieBanner', false) }
  },

  async fetchStoryblokSettings ({ dispatch }) {
    try {
      const resp = await this.$storyapi.getStory('settings', {
        resolve_links: 'url'
      })
      if (!resp.data?.story?.content) { return }
      const settings = resp.data?.story?.content
      dispatch('settings/setAppSettings', settings)
    } catch (e) {
      console.error(e)
    }
    // try {
    //   const resp = await this.$storyapi.getStories({
    //     is_startpage: false,
    //     starts_with: 'orte/',
    //     sort_by: 'content.title:asc',
    //     per_page: 100
    //   })
    //   if (!resp.data?.stories) { return }
    //   const stories = resp.data?.stories
    //   dispatch('locations/updateActionLocations', stories)
    // } catch (e) {
    //   console.error(e)
    // }
    try {
      const resp = await this.$storyapi.getStories({
        is_startpage: false,
        starts_with: 'kategorien/',
        sort_by: 'content.title:asc',
        per_page: 100
      })
      if (!resp.data?.stories) { return }
      const stories = resp.data?.stories
      dispatch('categories/updateActionCategories', stories)
    } catch (e) {
      console.error(e)
    }
    try {
      const resp = await this.$storyapi.getStories({
        is_startpage: false,
        starts_with: 'sponsoren/',
        sort_by: 'content.priority:desc',
        per_page: 100
      })
      if (!resp.data?.stories) { return }
      const stories = resp.data?.stories
      dispatch('sponsors/updateActionSponsors', stories)
    } catch (e) {
      console.error(e)
    }
  },

  parseAppCookies ({ dispatch }) {
    dispatch('gdpr/parseCookies')
  }
}

export default {
  actions
}
