export const Blok = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/Blok.vue' /* webpackChunkName: "components/blok" */).then(c => wrapFunctional(c.default || c))
export const BlokDebug = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/BlokDebug.vue' /* webpackChunkName: "components/blok-debug" */).then(c => wrapFunctional(c.default || c))
export const BlokDebugCodePreview = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/BlokDebugCodePreview.vue' /* webpackChunkName: "components/blok-debug-code-preview" */).then(c => wrapFunctional(c.default || c))
export const BlokDebugContentType = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/BlokDebugContentType.vue' /* webpackChunkName: "components/blok-debug-content-type" */).then(c => wrapFunctional(c.default || c))
export const BlokDebugDetails = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/BlokDebugDetails.vue' /* webpackChunkName: "components/blok-debug-details" */).then(c => wrapFunctional(c.default || c))
export const BlokDebugProps = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/BlokDebugProps.vue' /* webpackChunkName: "components/blok-debug-props" */).then(c => wrapFunctional(c.default || c))
export const InfinitePagination = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/SbQuery/InfinitePagination.vue' /* webpackChunkName: "components/infinite-pagination" */).then(c => wrapFunctional(c.default || c))
export const NumericPagination = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/SbQuery/NumericPagination.vue' /* webpackChunkName: "components/numeric-pagination" */).then(c => wrapFunctional(c.default || c))
export const SbQuery = () => import('../../node_modules/@nujek/storyblok/dist/runtime/components/SbQuery/SbQuery.vue' /* webpackChunkName: "components/sb-query" */).then(c => wrapFunctional(c.default || c))
export const NjBurger = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/NjBurger.vue' /* webpackChunkName: "components/nj-burger" */).then(c => wrapFunctional(c.default || c))
export const NjHeading = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/NjHeading.vue' /* webpackChunkName: "components/nj-heading" */).then(c => wrapFunctional(c.default || c))
export const NjImage = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/NjImage.vue' /* webpackChunkName: "components/nj-image" */).then(c => wrapFunctional(c.default || c))
export const NjLogo = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/NjLogo.vue' /* webpackChunkName: "components/nj-logo" */).then(c => wrapFunctional(c.default || c))
export const NjSection = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/NjSection.vue' /* webpackChunkName: "components/nj-section" */).then(c => wrapFunctional(c.default || c))
export const SbImage = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/SbImage.vue' /* webpackChunkName: "components/sb-image" */).then(c => wrapFunctional(c.default || c))
export const SbRichtext = () => import('../../node_modules/@nujek/ui/dist/runtime/components/atoms/SbRichtext.vue' /* webpackChunkName: "components/sb-richtext" */).then(c => wrapFunctional(c.default || c))
export const SbGrid = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/SbGrid.vue' /* webpackChunkName: "components/sb-grid" */).then(c => wrapFunctional(c.default || c))
export const NjNav = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjNav/NjNav.vue' /* webpackChunkName: "components/nj-nav" */).then(c => wrapFunctional(c.default || c))
export const NjNavDropdownItem = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjNav/NjNavDropdownItem.vue' /* webpackChunkName: "components/nj-nav-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const NjNavItem = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjNav/NjNavItem.vue' /* webpackChunkName: "components/nj-nav-item" */).then(c => wrapFunctional(c.default || c))
export const NjNavItems = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjNav/NjNavItems.vue' /* webpackChunkName: "components/nj-nav-items" */).then(c => wrapFunctional(c.default || c))
export const NjSlideOverMenu = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjNav/NjSlideOverMenu.vue' /* webpackChunkName: "components/nj-slide-over-menu" */).then(c => wrapFunctional(c.default || c))
export const NjSidebar = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjSidebar/NjSidebar.vue' /* webpackChunkName: "components/nj-sidebar" */).then(c => wrapFunctional(c.default || c))
export const NjSidebarTitle = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjSidebar/NjSidebarTitle.vue' /* webpackChunkName: "components/nj-sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const NjTabs = () => import('../../node_modules/@nujek/ui/dist/runtime/components/molecules/NjTabs/NjTabs.vue' /* webpackChunkName: "components/nj-tabs" */).then(c => wrapFunctional(c.default || c))
export const NjVideoBackground = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/NjVideoBackground.vue' /* webpackChunkName: "components/nj-video-background" */).then(c => wrapFunctional(c.default || c))
export const VideoOverlay = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/_sub/VideoOverlay.vue' /* webpackChunkName: "components/video-overlay" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/_sub/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoPoster = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/_sub/VideoPoster.vue' /* webpackChunkName: "components/video-poster" */).then(c => wrapFunctional(c.default || c))
export const PlayerProps = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/core/playerProps.js' /* webpackChunkName: "components/player-props" */).then(c => wrapFunctional(c.default || c))
export const Props = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/core/props.js' /* webpackChunkName: "components/props" */).then(c => wrapFunctional(c.default || c))
export const Resize = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/core/resize.js' /* webpackChunkName: "components/resize" */).then(c => wrapFunctional(c.default || c))
export const Throttle = () => import('../../node_modules/@nujek/ui/dist/runtime/components/organisms/NjVideoBackground/lib/throttle.js' /* webpackChunkName: "components/throttle" */).then(c => wrapFunctional(c.default || c))
export const BlokBulletlist = () => import('../../components/bloks/BlokBulletlist.vue' /* webpackChunkName: "components/blok-bulletlist" */).then(c => wrapFunctional(c.default || c))
export const BlokChecklist = () => import('../../components/bloks/BlokChecklist.vue' /* webpackChunkName: "components/blok-checklist" */).then(c => wrapFunctional(c.default || c))
export const BlokGrid = () => import('../../components/bloks/BlokGrid.vue' /* webpackChunkName: "components/blok-grid" */).then(c => wrapFunctional(c.default || c))
export const BlokImage = () => import('../../components/bloks/BlokImage.vue' /* webpackChunkName: "components/blok-image" */).then(c => wrapFunctional(c.default || c))
export const BlokIntroduction = () => import('../../components/bloks/BlokIntroduction.vue' /* webpackChunkName: "components/blok-introduction" */).then(c => wrapFunctional(c.default || c))
export const BlokRichtext = () => import('../../components/bloks/BlokRichtext.vue' /* webpackChunkName: "components/blok-richtext" */).then(c => wrapFunctional(c.default || c))
export const BlokTableOfContents = () => import('../../components/bloks/BlokTableOfContents.vue' /* webpackChunkName: "components/blok-table-of-contents" */).then(c => wrapFunctional(c.default || c))
export const BlokTextButton = () => import('../../components/bloks/BlokTextButton.vue' /* webpackChunkName: "components/blok-text-button" */).then(c => wrapFunctional(c.default || c))
export const Landingpage = () => import('../../components/content-types/Landingpage.vue' /* webpackChunkName: "components/landingpage" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/layout-components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../components/layout-components/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeft = () => import('../../components/utils/ArrowLeft.vue' /* webpackChunkName: "components/arrow-left" */).then(c => wrapFunctional(c.default || c))
export const ArrowRight = () => import('../../components/utils/ArrowRight.vue' /* webpackChunkName: "components/arrow-right" */).then(c => wrapFunctional(c.default || c))
export const Bolt = () => import('../../components/utils/Bolt.vue' /* webpackChunkName: "components/bolt" */).then(c => wrapFunctional(c.default || c))
export const BulletlistItem = () => import('../../components/utils/BulletlistItem.vue' /* webpackChunkName: "components/bulletlist-item" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/utils/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CategoryDropdown = () => import('../../components/utils/CategoryDropdown.vue' /* webpackChunkName: "components/category-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Check = () => import('../../components/utils/Check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c))
export const CheckCircleOutline = () => import('../../components/utils/CheckCircleOutline.vue' /* webpackChunkName: "components/check-circle-outline" */).then(c => wrapFunctional(c.default || c))
export const CheckCircleSolid = () => import('../../components/utils/CheckCircleSolid.vue' /* webpackChunkName: "components/check-circle-solid" */).then(c => wrapFunctional(c.default || c))
export const ChecklistItem = () => import('../../components/utils/ChecklistItem.vue' /* webpackChunkName: "components/checklist-item" */).then(c => wrapFunctional(c.default || c))
export const CustomHeading = () => import('../../components/utils/CustomHeading.vue' /* webpackChunkName: "components/custom-heading" */).then(c => wrapFunctional(c.default || c))
export const CustomSbRichtext = () => import('../../components/utils/CustomSbRichtext.vue' /* webpackChunkName: "components/custom-sb-richtext" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../components/utils/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const InnGrid = () => import('../../components/utils/InnGrid.vue' /* webpackChunkName: "components/inn-grid" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../components/utils/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const LinkIcon = () => import('../../components/utils/LinkIcon.vue' /* webpackChunkName: "components/link-icon" */).then(c => wrapFunctional(c.default || c))
export const LinkedIn = () => import('../../components/utils/LinkedIn.vue' /* webpackChunkName: "components/linked-in" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/utils/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/utils/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const SponsorCard = () => import('../../components/utils/SponsorCard.vue' /* webpackChunkName: "components/sponsor-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsButton = () => import('../../components/utils/UtilsButton.vue' /* webpackChunkName: "components/utils-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsCheckButton = () => import('../../components/utils/UtilsCheckButton.vue' /* webpackChunkName: "components/utils-check-button" */).then(c => wrapFunctional(c.default || c))
export const Whatsapp = () => import('../../components/utils/Whatsapp.vue' /* webpackChunkName: "components/whatsapp" */).then(c => wrapFunctional(c.default || c))
export const CookieBanner = () => import('../../components/utils/CookieBanner/CookieBanner.vue' /* webpackChunkName: "components/cookie-banner" */).then(c => wrapFunctional(c.default || c))
export const CookieSetting = () => import('../../components/utils/CookieBanner/CookieSetting.vue' /* webpackChunkName: "components/cookie-setting" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/utils/Dropdown/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownOverlay = () => import('../../components/utils/Dropdown/DropdownOverlay.vue' /* webpackChunkName: "components/dropdown-overlay" */).then(c => wrapFunctional(c.default || c))
export const UtilsFilter = () => import('../../components/utils/Filter/UtilsFilter.vue' /* webpackChunkName: "components/utils-filter" */).then(c => wrapFunctional(c.default || c))
export const Utils = () => import('../../components/utils/Filter/utils.js' /* webpackChunkName: "components/utils" */).then(c => wrapFunctional(c.default || c))
export const Map = () => import('../../components/utils/Map/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c))
export const MapWrapper = () => import('../../components/utils/Map/MapWrapper.vue' /* webpackChunkName: "components/map-wrapper" */).then(c => wrapFunctional(c.default || c))
export const OpeningHours = () => import('../../components/utils/OpeningHours/OpeningHours.vue' /* webpackChunkName: "components/opening-hours" */).then(c => wrapFunctional(c.default || c))
export const Radio = () => import('../../components/utils/RadioGroup/Radio.vue' /* webpackChunkName: "components/radio" */).then(c => wrapFunctional(c.default || c))
export const RadioGroup = () => import('../../components/utils/RadioGroup/RadioGroup.vue' /* webpackChunkName: "components/radio-group" */).then(c => wrapFunctional(c.default || c))
export const Sidescroll = () => import('../../components/utils/Sidescroll/Sidescroll.vue' /* webpackChunkName: "components/sidescroll" */).then(c => wrapFunctional(c.default || c))
export const SubButton = () => import('../../components/utils/Sub/SubButton.vue' /* webpackChunkName: "components/sub-button" */).then(c => wrapFunctional(c.default || c))
export const CustomNjImage = () => import('../../components/utils/images/CustomNjImage.vue' /* webpackChunkName: "components/custom-nj-image" */).then(c => wrapFunctional(c.default || c))
export const CustomSbImage = () => import('../../components/utils/images/CustomSbImage.vue' /* webpackChunkName: "components/custom-sb-image" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
