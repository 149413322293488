export const state = () => ({
  locations: []
})

export const getters = {
  getterLocations: (state) => {
    return state.locations
  }
}

export const mutations = {
  updateLocations: (state, payload) => {
    state.locations = payload
  }
}

export const actions = {
  updateActionLocations ({ commit }, payload) {
    commit('updateLocations', payload)
  }
}
