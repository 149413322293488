import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=49e7bfb1&scoped=true&"
import script from "./Nav.vue?vue&type=script&lang=js&"
export * from "./Nav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e7bfb1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArrowLeft: require('/vercel/path0/components/utils/ArrowLeft.vue').default,Bolt: require('/vercel/path0/components/utils/Bolt.vue').default,NjSection: require('/vercel/path0/node_modules/@nujek/ui/dist/runtime/components/atoms/NjSection.vue').default,RadioGroup: require('/vercel/path0/components/utils/RadioGroup/RadioGroup.vue').default})
