import defu from 'defu'

export const state = () => ({
  view: 0,
  viewlist: [
    { id: 'list', text: 'Liste' },
    { id: 'map', text: 'Karte' }
  ]
})

export const getters = {
  getterView: (state) => {
    return state.view
  },
  getterViewlist: (state) => {
    return state.viewlist
  },
  getterViewId: (state) => {
    return state.viewlist[state.view]?.id || ''
  }
}

export const mutations = {
  updateView: (state, payload) => {
    state.view = payload
  }
}

export const actions = {
  updateView ({ commit, state }, payload) {
    const route = this.$router.currentRoute
    if (payload === 0) {
      this.$router.push({
        ...route,
        ...{
          query: Object.keys(route.query).filter(key => key !== 'view').reduce((p, c) => {
            p[c] = route.query[c]
            return p
          }, {})
        }
      })
    } else {
      const viewId = state.viewlist[payload]?.id
      if (viewId) {
        this.$router.push(defu({ query: { view: viewId } }, route))
      } else {
        this.$router.push({
          ...route,
          ...{
            query: Object.keys(route.query).filter(key => key !== 'view').reduce((p, c) => {
              p[c] = route.query[c]
              return p
            }, {})
          }
        })
      }
    }
    commit('updateView', payload)
  }
}
